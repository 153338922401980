var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.addPersonnel },
              },
              [_vm._v("添加人员")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "728px",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.pManagerList.dialogTitle))]),
          ]),
          _vm.dialogVisible == true
            ? _c("personnelDialog", {
                attrs: {
                  id: _vm.pManagerList.id,
                  dialogStatus: _vm.pManagerList.dialogStatus,
                },
                on: { close: _vm.handleClose },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }