var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "presonnel-manager-dialog" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _vm.dialogStatus !== "edit"
            ? _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "perName" } },
                        [
                          _c("UserSelect", {
                            on: { change: _vm.changeHandler },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogStatus !== "edit"
            ? _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账户" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", disabled: "" },
                            model: {
                              value: _vm.ruleForm.account,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "account", $$v)
                              },
                              expression: "ruleForm.account",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogStatus !== "edit"
            ? _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", disabled: "" },
                            model: {
                              value: _vm.ruleForm.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "phoneNumber", $$v)
                              },
                              expression: "ruleForm.phoneNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "职务", prop: "duties" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "310px" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.changeDuties },
                          model: {
                            value: _vm.ruleForm.duties,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "duties", $$v)
                            },
                            expression: "ruleForm.duties",
                          },
                        },
                        _vm._l(_vm.dutiesOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "直属上级" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "310px" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.changeSuperior },
                          model: {
                            value: _vm.ruleForm.superior,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "superior", $$v)
                            },
                            expression: "ruleForm.superior",
                          },
                        },
                        _vm._l(_vm.superiorOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", disabled: true },
                            model: {
                              value: _vm.ruleForm.mailNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "mailNumber", $$v)
                              },
                              expression: "ruleForm.mailNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "58px" },
              attrs: { align: "right" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "plain" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("close")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("save")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }