<template>
    <div class="presonnel-manager-dialog">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
            <el-row :gutter="48" v-if="dialogStatus !== 'edit'">
                <el-col :span="12">
                    <el-form-item label="姓名" prop="perName">
                        <!-- <el-select 
                            v-model="ruleForm.perName" 
                            filterable 
                            remote 
                            placeholder="请输入"
                            clearable
                            :remote-method="filterAccounts" 
                            :loading="loading" 
                            @visible-change="cancalReadOnly"
                            @change="changeAccount"
                            ref="select" @hook:mounted="cancalReadOnly">
                            <el-option v-for="item in accountList" :key="item.userAccount"
                                :label="item.userName ? item.userName : item.userAccount" :value="item">
                            </el-option>
                        </el-select> -->
                        <UserSelect @change="changeHandler" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48" v-if="dialogStatus !== 'edit'">
                <el-col :span="12">
                    <el-form-item label="账户">
                        <el-input v-model="ruleForm.account" placeholder="请输入" disabled></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48" v-if="dialogStatus !== 'edit'">
                <el-col :span="12">
                    <el-form-item label="手机号码">
                        <el-input v-model="ruleForm.phoneNumber" placeholder="请输入" disabled></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="职务" prop="duties">
                        <el-select v-model="ruleForm.duties" filterable placeholder="请选择" style="width: 310px;"
                            @change="changeDuties">
                            <el-option v-for="item in dutiesOptions" :key="item.value" :label="item.label"
                                :value="item.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="直属上级">
                        <el-select v-model="ruleForm.superior" filterable placeholder="请选择" style="width: 310px;"
                            @change="changeSuperior">
                            <el-option v-for="item in superiorOptions" :key="item.value" :label="item.label"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row :gutter="48" v-if="dialogStatus == 'edit'">
                <el-col :span="12">
                    <el-form-item label="微信号">
                        <el-input v-model="ruleForm.wxNumber" placeholder="请输入" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row> -->
            <el-row :gutter="48" v-if="dialogStatus == 'edit'">
                <el-col :span="12">
                    <el-form-item label="邮箱">
                        <el-input v-model="ruleForm.mailNumber" placeholder="请输入" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item align="right" style="margin-top: 58px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button type="primary" @click="clickStep('save')">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {
    addPersonnel,
    getsuperiorData,
    getUserList,
    getUserDetail,
    updatePersonnel
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import { findLookupItem } from "@/api/ruge/lookup/lookup";
import UserSelect from "@/components/AccountSelect"
export default {
    components: {
        UserSelect
    },
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
    },
    data() {
        return {
            ruleForm: {
                perName: null,
                account: '',
                phoneNumber: '',
                duties: '',
                superior: {},
                wxNumber: '',
                mailNumber: ''
            },
            rules: {
                perName: [
                    { required: true, message: '必填项', trigger: 'blur' }
                ],
                duties: [
                    { required: true, message: '必填项', trigger: 'blur' }
                ]
            },
            dutiesOptions: [],
            superiorOptions: [],
            accountList: [],
            loading: false,
            editId: '',
        }
    },
    created() {
        console.log('取消');
        this.getFeedbackType()
        this.getsuperiorList()
    },
    methods: {
        changeDuties(value) {
            console.log(value, '选中');
        },
        changeSuperior(value) {
            console.log(value, '选中');
        },
        changeHandler(selected) {
            console.log(selected, 'selected');
            this.ruleForm.account = selected.userAccount
            this.ruleForm.phoneNumber = selected.phone
            this.ruleForm.perName = selected.userName
            this.ruleForm.email = selected.email
        },
        clickStep(type) {
            if (type == 'close') {
                // this.ruleForm.duties = '',
                // this.ruleForm.superior = {},
                this.$emit("close", false);
            } else if (type == 'save') {
                if (this.dialogStatus == 'add') {

                    this.$refs["ruleForm"].validate((valid) => {
                        if (valid) {
                            addPersonnel({
                                employeeName: this.ruleForm.perName,
                                userAccount: this.ruleForm.account,
                                userPhone: this.ruleForm.phoneNumber,
                                roleName: this.ruleForm.duties,
                                directSupervisor: this.ruleForm.superior.label,
                                supervisorUserAccount: this.ruleForm.superior.value,
                                email: this.ruleForm.email,
                                wxchatNum: ''
                            }).then(res => {
                                console.log(res);
                                if (res.code == 200) {
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                    this.$emit("close", false);
                                }
                            })
                        }
                    })
                } else if (this.dialogStatus == 'edit') {
                    console.log('222');
                    let arr = {
                        id: this.editId,
                        directSupervisor: this.ruleForm.superior.label,
                        supervisorUserAccount: this.ruleForm.superior.value,
                        roleName: this.ruleForm.duties
                    }
                    console.log(arr);
                    updatePersonnel(arr).then(res => {
                        if (res.code == 200) {
                            this.$emit("close", true);
                            console.log(res);
                        } else if (res.code == 500) {
                            this.$message({
                                message: res.msg,
                                type: 'error'
                            });
                        }
                    })
                }
            }
        },
        // 数据字典 - 获取反馈类型
        getFeedbackType() {
            this.dutiesOptions = []
            findLookupItem("HMS_ROLE_TYPE").then((row) => {
                console.log(row, '字典');
                row.map((item) => {
                    this.dutiesOptions.push({
                        value: item.itemName,
                        label: item.itemName,
                    })
                })
            });
        },
        // 获取直属上级数据列表
        getsuperiorList() {
            this.superiorOptions = []
            getsuperiorData().then(res => {
                console.log(res, '直属上级列表');
                res.data.map((item) => {
                    this.superiorOptions.push({
                        value: item.userAccount,
                        label: item.employeeName,
                    })
                })
            })
        },
        filterAccounts(query) {
            this.loading = true;
            getUserList({
                filter: query
            }).then(res => {
                if (res) {
                    this.loading = false;
                    this.accountList = res
                }
            })
        },
        cancalReadOnly(onOff) {
            this.$nextTick(() => {
                if (!onOff) {
                    const { select } = this.$refs;
                    const input = select.$el.querySelector(".el-input__inner");
                    input.removeAttribute("readonly");
                }
            });
        },
        changeAccount(val) {
            console.log(val);
        },
        getUserData(id) {
            this.editId = id
            getUserDetail({ id }).then(res => {
                console.log(res, 'res');
                this.ruleForm.duties = res.data.roleName
                this.ruleForm.superior = {
                    value: res.data.supervisorUserAccount,
                    label: res.data.directSupervisor,
                }
                this.ruleForm.mailNumber = res.data.email
            })
            console.log('111111', this.ruleForm);
        }
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                this.getUserData(id)
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
            },
            immediate: true,
        },
        'ruleForm.perName': function (newVal) {
            this.accountList = []
            this.filterAccounts(newVal)
        },
    }
}
</script>


<style lang="less" scoped>
.presonnel-manager-dialog {}
</style>