<template>
    <div class="app-container">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="addPersonnel">添加人员</el-button>
            </span>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" />
        </div>
        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" width="728px" :destroy-on-close="true">
            <template slot="title">
                <span>{{ pManagerList.dialogTitle }}</span>
            </template>
            <personnelDialog @close="handleClose" v-if="dialogVisible == true" :id="pManagerList.id" :dialogStatus="pManagerList.dialogStatus">
            </personnelDialog>
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import personnelDialog from "./personnelDialog.vue"
import {
    getcustomerList,
    deleteUserId
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
export default {
    components: {
        finalTable,
        personnelDialog
    },
    data() {
        return {
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "employeeName", label: "姓名", width: "" },
                    { prop: "directSupervisor", label: "直属上级", width: "" },
                    { prop: "userAccount", label: "账号", width: "" },
                    { prop: "userPhone", label: "手机号码", width: "" },
                    { prop: "email", label: "邮箱", width: "" },
                    { prop: "operation", label: "操作", width: "100" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {},
                // 表格内容配置
                detailConfig: {
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "edit",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#E8331C",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "删除",
                            },
                        ],
                    }
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            dialogVisible: false,
            pManagerList: {
                dialogStatus: 'add',
                dialogTitle: "添加角色",
            },
            personnelObj: {
                listQuery: {
                    current: 1,
                    rowCount: 10,
                }
            }
        }
    },
    created() {
        this.getTableList()
    },
    methods: {
        getTableList() {
            console.log('111');
            const params = { ...this.personnelObj.listQuery };
            getcustomerList(params).then(res => {
                this.dataset.pageVO.total = res.data.total;
                this.dataset.tableData = res.data.rows;
            })
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.personnelObj.listQuery = {
                    ...this.personnelObj.listQuery,
                    ...datas.params,
                };
                this.personnelObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "paginationChange") {
                this.personnelObj.listQuery.current = datas.params.current.page;
                this.personnelObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "edit":
                        this.pManagerList.id = datas.row.id;
                        this.pManagerList.dialogStatus = "edit";
                        this.pManagerList.dialogTitle = "编辑人员";
                        this.dialogVisible = true;
                        break;
                    case "delete":
                        this.singleDeleteHandler(datas.row);
                }
            }
        },
        singleDeleteHandler(row) {
            this.$confirm(
                this.$t("message.deleteConfirm"),
                this.$t("commons.warning"),
                {
                    confirmButtonText: this.$t("commons.confirm"),
                    cancelButtonText: this.$t("commons.cancel"),
                    type: "warning",
                }
            ).then(() => {
                console.log(row,'row');
                deleteUserId({
                    id: row.id,
                    userAccount: row.userAccount
                }).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableList()
                    } else if (res.code == 424) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    } else if(res.code == 500) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        handleClose(freshFlag) {
            this.dialogVisible = false
            freshFlag && this.getTableList();
        },
        addPersonnel() {
            this.dialogVisible = true
            this.pManagerList.id = null;
            this.pManagerList.dialogStatus = "add";
            this.pManagerList.dialogTitle = "添加人员";
        }
    }
}
</script>
<style lang="less" scoped>
.filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        height: 36px;
    }

    .icon-loudoutu {
        font-size: 12px;
    }

    .header-filter-button {
        cursor: pointer;
        width: 84px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
    }

    .filter-button-open {
        color: #409eff;
        background: #ecf5ff;
        border: 1px solid #c6e2ff;
    }

    .filter-button-close {
        color: #2a4158;
        background: #ffffff;
        border: 1px solid #e7e8eb;
    }
}
</style>